import Button, {BASE_CLASS, SIZE_CLASSES, STYLE_CLASSES, THEME_CLASSES} from '.';
import {ArrowSquareOut, Hand, Question} from '@phosphor-icons/react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import useChatbot from '@/hooks/useChatbot';
import useHelp, {HelpTopics} from '@/hooks/useHelp';
import useI18n from '@/hooks/useI18n';
import useMixpanel from '@/hooks/useMixpanel';

interface Props {
    topic: HelpTopics;
}

const HelpButton: React.FC<Props> = ({topic}) => {
    const {openChatbot} = useChatbot();
    const {trackHelpOpened, trackHelpCtaClicked} = useMixpanel();
    const {getTopic} = useHelp();
    const {t} = useI18n();
    const content = getTopic(topic);

    const trackOpen = (open: boolean) => {
        if (open) {
            trackHelpOpened(topic);
        }
    };

    const trackClick = () => trackHelpCtaClicked(topic);

    if (!content) return null;

    return (
        <HoverCardPrimitive.Root openDelay={0} onOpenChange={trackOpen}>
            <HoverCardPrimitive.Trigger asChild>
                <button
                    type="button"
                    className={`${BASE_CLASS} ${STYLE_CLASSES.circle} ${THEME_CLASSES.secondary} ${SIZE_CLASSES.sm} justify-center !px-1.5`}
                >
                    <Question className="h-5 w-5" weight="bold" />
                </button>
            </HoverCardPrimitive.Trigger>
            <HoverCardPrimitive.Portal>
                <HoverCardPrimitive.Content
                    className="z-[100] w-[340px] rounded-xl border border-gray-200 bg-white p-4 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:transition-all"
                    sideOffset={5}
                >
                    <p className="whitespace-pre-line text-sm text-teal-800">
                        {Array.isArray(content.text) ? content.text.join('\n') : content.text}
                    </p>
                    <div className="mt-3 flex items-center space-x-2">
                        {content.url && (
                            <Button
                                className="w-full justify-center"
                                href={content.url}
                                onClick={trackClick}
                                size="sm"
                                theme="secondary"
                            >
                                <span>{t('general.visitWiki')}</span>
                                <ArrowSquareOut className="h-5 w-5" />
                            </Button>
                        )}
                        <Button
                            className="w-full justify-center"
                            onClick={() => openChatbot('help button', {topic})}
                            size="sm"
                            theme="white"
                        >
                            <span>Open chat</span>
                            <Hand className="h-5 w-5" />
                        </Button>
                    </div>
                    <HoverCardPrimitive.Arrow className="translate-y-[-1px] fill-white" />
                </HoverCardPrimitive.Content>
            </HoverCardPrimitive.Portal>
        </HoverCardPrimitive.Root>
    );
};

export default HelpButton;
