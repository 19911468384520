import {Warning} from '@phosphor-icons/react';
import Empty from '@/components/Empty';

interface Props {
    message: string;
}

const ErrorMessage: React.FC<Props> = ({message}) => (
    <div className="m-6 flex min-h-full animate-shake">
        <Empty
            Icon={<Warning className="h-8 w-8 text-red-600" />}
            title={'Something went wrong.'}
            text={
                'Sorry, an error occurred that could not be recovered. Please refresh the page and try again, or contact us if the problem persists.'
            }
        >
            <code className="block rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs">
                {message}
            </code>
        </Empty>
    </div>
);

export default ErrorMessage;
