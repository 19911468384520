import {forwardRef} from 'react';
import clsx from 'clsx';
import FieldWrapper from './FieldWrapper';

export const INPUT_CLASSES = (
    fieldClasses = '',
    error = '',
    disabled = false,
    textDirection = 'ltr'
) => {
    return clsx(
        `form-input block w-full rounded-lg border text-sm text-gray-800 shadow-sm transition duration-200 ease-out placeholder:text-gray-400 focus:ring-2 focus:ring-offset-2 sm:text-sm ${fieldClasses}`,
        {
            'border-red-200 bg-red-50 bg-opacity-50 focus:border-red-300 focus:ring-red-300':
                !!error,
            'border-gray-200 bg-white focus:border-gray-200 focus:ring-gray-200': !error,
            'pointer-events-none cursor-not-allowed !bg-gray-50 !text-gray-400': disabled,
            'hover:border-gray-300': !disabled,
            'direction-rtl text-right': textDirection === 'rtl'
        }
    );
};

type Props = React.ComponentPropsWithRef<'input'> & FormField;

const FormInput: React.FC<Props> = forwardRef(
    (
        {
            badge,
            description,
            error,
            fieldClasses = '',
            fieldWrapperClasses = '',
            label,
            labelClasses = '',
            showErrors = true,
            textDirection,
            textDirectionElements = 'input',
            wrapperClasses = '',
            ...props
        },
        ref: React.ForwardedRef<HTMLInputElement>
    ) => (
        <FieldWrapper
            badge={badge}
            description={description}
            error={error}
            fieldWrapperClasses={fieldWrapperClasses}
            id={props.id}
            label={label}
            labelClasses={labelClasses}
            showErrors={showErrors}
            textDirection={['all', 'label'].includes(textDirectionElements) ? textDirection : 'ltr'}
            wrapperClasses={wrapperClasses}
        >
            <input
                {...props}
                ref={ref}
                className={INPUT_CLASSES(
                    fieldClasses,
                    error,
                    props.disabled,
                    ['all', 'input'].includes(textDirectionElements) ? textDirection : 'ltr'
                )}
                maxLength={props.maxLength || undefined}
                type={props.type || 'text'}
            />
        </FieldWrapper>
    )
);

FormInput.displayName = 'FormInput';

export default FormInput;
