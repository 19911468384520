import {forwardRef, useState} from 'react';
import {useForm} from '@inertiajs/react';
import {CaretRight} from '@phosphor-icons/react';
import axios from 'axios';
import {toast} from 'sonner';
import AnimatedFadeIn from '@/components/Animated/FadeIn';
import Button from '@/components/Button';
import AlertDialog from '@/components/Dialog/Alert';
import FormInput from '@/components/Form/Input';
import IndicatorBadge from '@/components/IndicatorBadge';
import useData from '@/hooks/useData';
import useI18n from '@/hooks/useI18n';
import useMixpanel from '@/hooks/useMixpanel';
import useRoutes from '@/hooks/useRoutes';
import {getFormValuesAsObject} from '@/utils/helpers';

const OnboardingNewUserDialog: React.FC = forwardRef<HTMLDivElement>(() => {
    const {currentUser, usage} = useData(['currentUser', 'usage']);
    const {trackOnboardingComplete, trackTeamCreated} = useMixpanel();
    const {getRoute} = useRoutes();
    const {t} = useI18n();
    const defaultValues = {name: '', 'settings.legal_age_for_guardian_consent': ''};
    const form = useForm(defaultValues);
    const [processing, setProcessing] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);

    const handleSuccess = () => {
        trackTeamCreated(true);
        trackOnboardingComplete();
        setIsDialogOpen(false);
    };

    const handleCompleteOnboarding = async () => {
        if (processing) return;
        setProcessing(true);

        try {
            await axios.delete('/current-user/actions/onboarding');
            handleSuccess();
        } catch (error) {
            setProcessing(false);

            if (axios.isAxiosError(error)) {
                toast.error(error?.response?.data.message);
            } else {
                console.log(error);
            }
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        form.transform(data => ({...data, ...getFormValuesAsObject(data)}));
        form.post(getRoute('teams.store'), {onSuccess: handleCompleteOnboarding});
    };

    const guides = [
        {
            description: t('onboarding.step1Text'),
            position: t('onboarding.step1'),
            title: t('onboarding.step1Title')
        },
        {
            description: t('onboarding.step2Text'),
            position: t('onboarding.step2'),
            title: t('onboarding.step2Title')
        },
        {
            description: t('onboarding.step3Text'),
            position: t('onboarding.step3'),
            title: t('onboarding.step3Title')
        },
        {
            description: t('onboarding.step4Text'),
            position: t('onboarding.step4'),
            title: t('onboarding.step4Title')
        },
        {
            description: t('onboarding.step5Text'),
            position: t('onboarding.step5'),
            title: t('onboarding.step5Title')
        },
        {
            description: t('onboarding.step6Text'),
            position: t('onboarding.step6'),
            title: t('onboarding.step6Title')
        }
    ];

    if (currentUser.onboarded_at) return null;

    // If there are no teams we need to prompt the user to create one as part of their onboarding
    // experience.
    const showTeamCreation = !usage.teams_count;

    return (
        <AlertDialog.Root open={isDialogOpen}>
            <AlertDialog.Container containerClassName="!rounded-2xl !p-10" size="3xl" type="modal">
                <AnimatedFadeIn delay={0.2} duration={0.5}>
                    <h2 className="text-2xl font-medium text-teal-800">{t('onboarding.title')}</h2>
                    <p className="mt-2 text-gray-600">{t('onboarding.text')}</p>
                    <div className="my-6 grid w-full gap-2 rounded-2xl text-left md:grid-cols-2">
                        {guides.map(guide => (
                            <div
                                key={guide.title}
                                className="min-h-full rounded-xl bg-gray-100 p-4"
                            >
                                <h3 className="flex space-x-2 text-lg font-medium text-teal-600">
                                    <IndicatorBadge>{guide.position}</IndicatorBadge>
                                    <span>{guide.title}</span>
                                </h3>
                                <p
                                    className="mt-2 text-sm text-gray-600"
                                    dangerouslySetInnerHTML={{__html: guide.description}}
                                />
                            </div>
                        ))}
                    </div>
                    {showTeamCreation ? (
                        <>
                            <h3 className="text-lg font-medium text-teal-600">
                                {t('onboarding.createTeam.title')}
                            </h3>
                            <p className="mx-auto mt-2 flex max-w-lg items-center justify-center gap-2 text-sm text-gray-600">
                                {t('onboarding.createTeam.text')}
                            </p>
                            <form
                                className="mx-auto flex items-start space-x-2 py-4"
                                onSubmit={handleSubmit}
                            >
                                <FormInput
                                    error={form.errors.name}
                                    id="name"
                                    name="name"
                                    onChange={e => form.setData('name', e.target.value)}
                                    placeholder={t('onboarding.createTeam.name')}
                                    wrapperClasses="w-6/12 text-left"
                                    value={form.data.name}
                                />
                                <FormInput
                                    description={t('onboarding.createTeam.consentAgeDescription')}
                                    error={form.errors['settings.legal_age_for_guardian_consent']}
                                    id="settings.legal_age_for_guardian_consent"
                                    name="settings.legal_age_for_guardian_consent"
                                    onChange={e =>
                                        form.setData(
                                            'settings.legal_age_for_guardian_consent',
                                            e.target.value
                                        )
                                    }
                                    placeholder={t('onboarding.createTeam.consentAge')}
                                    wrapperClasses="w-6/12 text-left"
                                    value={form.data['settings.legal_age_for_guardian_consent']}
                                />
                                <Button
                                    className="mt-[1.5px]"
                                    isFetching={form.processing}
                                    type="submit"
                                >
                                    {t('general.continue')}
                                </Button>
                            </form>
                        </>
                    ) : (
                        <Button
                            disabled={processing}
                            isFetching={processing}
                            onClick={handleCompleteOnboarding}
                        >
                            <span>{t('onboarding.createTeam.complete')}</span>
                            <CaretRight className="h-5 w-5" />
                        </Button>
                    )}
                </AnimatedFadeIn>
            </AlertDialog.Container>
        </AlertDialog.Root>
    );
});
OnboardingNewUserDialog.displayName = 'OnboardingNewUserDialog';

export default OnboardingNewUserDialog;
