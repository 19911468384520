import {PropsWithChildren} from 'react';
import ErrorHeader from './ErrorHeader';

interface Props {
    align?: keyof typeof ALIGNMENT_CLASSES;
    Icon?: React.ReactNode;
    title?: string;
    type?: keyof typeof THEME_CLASSES;
}

const ALIGNMENT_CLASSES = {
    left: 'text-left justify-start',
    center: 'text-center justify-center'
};

const THEME_CLASSES = {
    empty: 'border-dashed border-gray-200 text-gray-500',
    error: 'bg-red-50 border-opacity-30 bg-opacity-50 border-red-400 text-red-500',
    info: 'border-opacity-30 border-gray-400 border-gray-400 text-gray-600'
};

const EmptyPanel: React.FC<PropsWithChildren<Props>> = ({
    align = 'center',
    children,
    Icon = null,
    title,
    type = 'empty'
}) => (
    <div
        className={`flex w-full flex-grow animate-fadeIn flex-col justify-center overflow-hidden rounded-xl border-2 p-8 sm:p-6 sm:py-12 lg:p-8 ${THEME_CLASSES[type]} ${ALIGNMENT_CLASSES[align]}`}
    >
        {Icon && <div className="mb-2 flex justify-center">{Icon}</div>}
        {type === 'error' && (
            <div className={`flex ${ALIGNMENT_CLASSES[align]}`}>
                <ErrorHeader title={title} />
            </div>
        )}
        {type !== 'error' && title && (
            <div className={`flex font-medium text-gray-800 ${ALIGNMENT_CLASSES[align]}`}>
                {title}
            </div>
        )}
        <span className="mx-auto block w-full max-w-md space-y-4 self-center align-middle text-sm">
            {children}
        </span>
    </div>
);

export default EmptyPanel;
