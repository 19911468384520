import {WarningCircle} from '@phosphor-icons/react';

interface Props {
    title?: string;
}

const ErrorHeader: React.FC<Props> = ({title}) => (
    <div className="mb-2 flex items-center space-x-1">
        <WarningCircle className="hidden h-6 w-6 sm:inline-block" />
        <strong className="text-sm font-medium underline decoration-2 underline-offset-2 sm:text-base">
            {title || 'Sorry, an error occurred'}
        </strong>
    </div>
);

export default ErrorHeader;
