import {useContext} from 'react';
import {captureException} from '@sentry/react';
import {MixpanelContext} from '@/components/Mixpanel';
import useData from './useData';

const useMixpanel = () => {
    const mixpanel = useContext(MixpanelContext);
    const {currentUser, plan, projectId, respondent, survey, state, team} = useData([
        'currentUser',
        'plan',
        'projectId',
        'respondent',
        'survey',
        'state',
        'team'
    ]);

    if (!mixpanel) {
        throw new Error('useMixpanel must be used within a MixpanelProvider');
    }

    const isTrial = state === 'none';
    const respondentId = respondent?.id ? String(respondent.id) : '';
    const subscriptionType = team?.subscription_type || plan?.name || '';
    const surveyId = survey?.id ? String(survey.id) : '';
    const teamId = team?.id ? String(team.id) : '';
    const tenantId = team?.id ? String(team.tenant_id) : '';

    const track = (event: string, properties?: any) => {
        if (!__MIXPANEL_TOKEN__) {
            console.log({event, properties});
            return;
        }

        try {
            mixpanel.track(event, properties);
        } catch (error) {
            captureException(error);
            console.error(error);
        }
    };

    const trackAndroidAppBannerClicked = () => {
        track('Android app banner clicked');
    };

    const trackErrorFallback = (type: 'app' | 'main', message: string) => {
        track('Error fallback shown', {type, message});
    };

    const trackErrorPage = (status: number) => {
        track('Error page shown', {status});
    };

    const trackLogin = (userId: number) => {
        try {
            mixpanel.identify(String(userId));
            track('Login', {userId: String(userId)});
        } catch (error) {
            console.error(error);
        }
    };

    const trackLogout = () => {
        try {
            mixpanel.reset();
            track('Logout');
        } catch (error) {
            console.error(error);
        }
    };

    const trackProjectEvent = (event: string, properties?: any) => {
        if (!team?.id) return;
        track(event, {isTrial, subscriptionType, teamId, tenantId, ...properties});
    };

    const trackRespondentEvent = (event: string, properties?: any) => {
        if (!respondent?.id) return;
        track(event, {isTrial, respondentId, subscriptionType, teamId, tenantId, ...properties});
    };

    const trackSubscriptionEvent = (event: string, properties?: any) => {
        track(event, {subscriptionType, tenantId, ...properties});
    };

    const trackSurveyEvent = (event: string, properties?: any) => {
        if (!team?.id || !survey?.id) return;
        track(event, {
            projectId: projectId || survey.project_id,
            subscriptionType,
            surveyId,
            teamId,
            tenantId,
            ...properties
        });
    };

    const trackTeamEvent = (event: string, properties?: any, requireTeamData = true) => {
        if (!team?.id && requireTeamData) return;

        track(event, {teamId, subscriptionType, tenantId, ...properties});
    };

    const trackConsentTemplateUpdated = () => {
        track('Track consent template updated');
    };

    const trackChatbotOpened = (source: string) => {
        track('Chatbot opened', {page: window.location.pathname, source});
    };

    const trackHelpOpened = (topic: string) => {
        track('Help opened', {topic});
    };

    const trackHelpCtaClicked = (topic: string) => {
        track('Help CTA clicked', {topic});
    };

    const trackShortcutClicked = (section: string, shortcut: string) => {
        trackSurveyEvent('Shortcut clicked', {section, shortcut});
    };

    const trackExternalLinkClicked = (title: string, via: string) => {
        track('External link clicked', {title, via});
    };

    const trackListFilterUpdated = (type: string, id: string, ...properties: any) => {
        track('List filter updated', {type, id, ...properties});
    };

    const trackRegistrationEmailConfirmationShown = () => {
        track('Registration email confirmation shown');
    };

    const trackRegistrationEmailConfirmationEdited = () => {
        track('Registration email confirmation edited');
    };

    const trackRegistrationSubmit = () => {
        track('Registration submitted');
    };

    const trackRegistrationComplete = (hasCompletedExtraFields: boolean) => {
        track('Registration complete', {hasCompletedExtraFields});
    };

    const trackRespondentDeleted = () => {
        trackRespondentEvent('Respondent deleted');
    };

    const trackRespondentPiiViewed = (type: string) => {
        trackRespondentEvent('Respondent PII viewed', {type});
    };

    const trackRespondentUpdated = () => {
        trackRespondentEvent('Respondent data updated');
    };

    const trackInterviewAnalysisOpened = (interviewId: number) => {
        trackSurveyEvent('Interview analysis dialog opened', {interviewId});
    };

    const trackInterviewNotesOpened = (interviewId: number) => {
        trackSurveyEvent('Interview notes dialog opened', {interviewId});
    };

    const trackInterviewDeleted = () => {
        trackSurveyEvent('Interview deleted');
    };

    const trackInterviewSafeguardingOpened = (interviewId: number) => {
        trackSurveyEvent('Interview safeguarding dialog opened', {interviewId});
    };

    const trackInterviewSafeguardingUpdated = (interviewId: number) => {
        trackSurveyEvent('Interview safeguarding updated', {interviewId});
    };

    const trackInterviewTagsOpened = (interviewId: number) => {
        trackSurveyEvent('Interview tags dialog opened', {interviewId});
    };

    const trackInterviewTagsUpdated = (interviewId: number) => {
        trackSurveyEvent('Interview tags updated', {interviewId});
    };

    const trackInterviewTranslationsOpened = (interviewId: number) => {
        trackSurveyEvent('Interview translations dialog opened', {interviewId});
    };

    const trackInterviewTranslationsUpdated = (interviewId: number) => {
        trackSurveyEvent('Interview translations updated', {interviewId});
    };

    const trackOnboardingComplete = () => {
        trackTeamEvent('Onboarding complete');
    };

    const trackProjectCreated = () => {
        trackProjectEvent('Project created');
    };

    const trackProjectDeleted = () => {
        trackProjectEvent('Project deleted');
    };

    const trackProjectUpdated = (projectId: number) => {
        trackProjectEvent('Project updated', {projectId: String(projectId)});
    };

    const trackSubscriptionScheduleToggled = (schedule: string) => {
        trackSubscriptionEvent('Subscription schedule interval toggled', {schedule});
    };

    const trackSubscriptionPlanDeselected = (
        plan: string,
        schedule: string,
        dialogOpenDurationSeconds: number
    ) => {
        trackSubscriptionEvent('Subscription plan deselected', {
            plan,
            schedule,
            dialogOpenDurationSeconds
        });
    };

    const trackSubscriptionPlanSelected = (
        plan: string,
        schedule: string,
        timeOnPageDurationSeconds: number
    ) => {
        trackSubscriptionEvent('Subscription plan selected', {
            plan,
            schedule,
            timeOnPageDurationSeconds
        });
    };

    const trackSubscriptionAddressCreated = (plan: string, schedule: string) => {
        trackSubscriptionEvent('Subscription plan address created', {plan, schedule});
    };

    const trackSubscriptionYearlyIncentiveShown = (plan: string) => {
        trackSubscriptionEvent('Subscription yearly incentive dialog shown', {plan});
    };

    const trackSubscriptionYearlyIncentiveCancelled = (plan: string) => {
        trackSubscriptionEvent('Subscription yearly incentive declined', {plan});
    };

    const trackSubscriptionYearlyIncentiveConfirmed = (plan: string) => {
        trackSubscriptionEvent('Subscription yearly incentive accepted', {plan});
    };

    const trackSubscriptionBespokePlanSelected = (plan: string) => {
        trackSubscriptionEvent('Subscription bespoke plan selected', {plan});
    };

    const trackSubscriptionCreatedAndPendingCompletion = () => {
        trackSubscriptionEvent('Subscription plan created and pending completion');
    };

    const trackSubscriptionCancelConfirmationOpened = (plan: string, schedule: string) => {
        trackSubscriptionEvent('Subscription plan cancellation dialog opened', {plan, schedule});
    };

    const trackSubscriptionCancelled = (plan: string, schedule: string) => {
        trackSubscriptionEvent('Subscription plan cancelled', {plan, schedule});
    };

    const trackSubscriptionResumeFromGracePeriodOpened = (plan: string, schedule: string) => {
        trackSubscriptionEvent(
            'Subscription plan cancelled and resume from grace period dialog opened',
            {plan, schedule}
        );
    };

    const trackSubscriptionResumeFromGracePeriodCompleted = (plan: string, schedule: string) => {
        trackSubscriptionEvent('Subscription plan cancelled then resumed from grace period', {
            plan,
            schedule
        });
    };

    const trackSubscriptionInvoicePendingPaymentClicked = (plan: string, schedule: string) => {
        trackSubscriptionEvent('Subscription invoice pending payment paid', {plan, schedule});
    };

    const trackSubscriptionInvoiceDownloaded = (plan: string, schedule: string) => {
        trackSubscriptionEvent('Subscription invoice downloaded', {plan, schedule});
    };

    const trackSubscriptionBusinessDetailsUpdated = () => {
        trackSubscriptionEvent('Subscription business details updated');
    };

    const trackSubscriptionDefaultPaymentMethodUpdated = () => {
        trackSubscriptionEvent('Subscription default payment method updated');
    };

    const trackSubscriptionPaymentMethodAdded = () => {
        trackSubscriptionEvent('Subscription payment method added');
    };

    const trackSubscriptionPaymentMethodRemoved = () => {
        trackSubscriptionEvent('Subscription payment method removed');
    };

    const trackSubscriptionChange = (newPlan: string, newSchedule: string) => {
        trackSubscriptionEvent('Subscription change', {newPlan, newSchedule});
    };

    const trackSurveyCreated = (type: string) => {
        if (!team?.id) return;

        track('Survey created', {
            projectId,
            teamId: String(team.id),
            tenantId: String(team.tenant_id),
            type
        });
    };

    const trackSurveyPublished = () => {
        trackSurveyEvent('Survey published');
    };

    const trackSurveyDataExported = (surveyId?: number) => {
        trackSurveyEvent('Survey data exported', {surveyId});
    };

    const trackSurveyConsentReordered = () => {
        trackSurveyEvent('Survey consent sections reordered');
    };

    const trackSurveyConsentPreviewOpened = () => {
        trackSurveyEvent('Survey consent preview opened');
    };

    const trackSurveyConsentUpdated = () => {
        trackSurveyEvent('Survey consent updated');
    };

    const trackSurveyDeleted = () => {
        trackSurveyEvent('Survey deleted');
    };

    const trackSurveyScriptAgeBracketsRangeGenerated = (
        min: number,
        max: number,
        interval: number
    ) => {
        trackSurveyEvent('Survey script age brackets gange generated', {min, max, interval});
    };

    const trackSurveyScriptOptionsTemplateSelected = (options: string[]) => {
        trackSurveyEvent('Survey script options template used', {options});
    };

    const trackSurveyScriptPreviewOpened = () => {
        trackSurveyEvent('Survey script preview opened');
    };

    const trackSurveyScriptQuestionRemoved = () => {
        trackSurveyEvent('Survey script question removed');
    };

    const trackSurveyScriptQuestionsReordered = () => {
        trackSurveyEvent('Survey script questions reordered');
    };

    const trackSurveyScriptUpdated = () => {
        trackSurveyEvent('Survey script updated');
    };

    const trackSurveyUpdated = () => {
        trackSurveyEvent('Survey updated');
    };

    const trackTagCreated = (name: string, language: string) => {
        trackSurveyEvent('Tag created', {name, language});
    };

    const trackTagSelected = (name: string, language: string) => {
        trackSurveyEvent('Tag selected', {name, language});
    };

    const trackTeamCreated = (fromOnboarding: boolean = false) => {
        trackTeamEvent('Team created', {fromOnboarding});
    };

    const trackTeamDeleted = () => {
        trackTeamEvent('Team deleted');
    };

    const trackTeamUpdated = () => {
        trackTeamEvent('Team updated');
    };

    const trackTeamMemberActivated = (teamMemberId: number) => {
        trackTeamEvent('Team member activated', {teamMemberId});
    };

    const trackTeamMemberCreated = () => {
        trackTeamEvent('Team member created');
    };

    const trackTeamMemberDeactivated = (teamMemberId: number) => {
        trackTeamEvent('Team member deactivated', {teamMemberId});
    };

    const trackTeamMemberDeleted = (teamMemberId: number, role: string) => {
        trackTeamEvent('Team member deleted', {teamMemberId, role}, false);
    };

    const trackTeamMemberUpdated = (teamMemberId: number) => {
        trackTeamEvent('Team member updated', {teamMemberId});
    };

    const trackTeamSettingsUpdated = (type: string) => {
        trackTeamEvent('Team settings updated', {type});
    };

    const trackUpgradeBannerClicked = (page: string) => {
        track('Trial subscription upgrade banner clicked', {
            page,
            tenant: currentUser?.tenant?.id || '',
            user: currentUser?.id || ''
        });
    };

    return {
        trackAndroidAppBannerClicked,
        trackErrorFallback,
        trackErrorPage,
        trackConsentTemplateUpdated,
        trackChatbotOpened,
        trackLogin,
        trackLogout,
        trackHelpOpened,
        trackHelpCtaClicked,
        trackInterviewAnalysisOpened,
        trackInterviewDeleted,
        trackInterviewNotesOpened,
        trackInterviewSafeguardingOpened,
        trackInterviewSafeguardingUpdated,
        trackInterviewTagsOpened,
        trackInterviewTagsUpdated,
        trackInterviewTranslationsOpened,
        trackInterviewTranslationsUpdated,
        trackExternalLinkClicked,
        trackListFilterUpdated,
        trackOnboardingComplete,
        trackProjectCreated,
        trackProjectDeleted,
        trackProjectUpdated,
        trackRegistrationEmailConfirmationShown,
        trackRegistrationEmailConfirmationEdited,
        trackRegistrationSubmit,
        trackRegistrationComplete,
        trackRespondentDeleted,
        trackRespondentPiiViewed,
        trackRespondentUpdated,
        trackShortcutClicked,
        trackSubscriptionScheduleToggled,
        trackSubscriptionPlanDeselected,
        trackSubscriptionPlanSelected,
        trackSubscriptionYearlyIncentiveShown,
        trackSubscriptionYearlyIncentiveCancelled,
        trackSubscriptionYearlyIncentiveConfirmed,
        trackSubscriptionAddressCreated,
        trackSubscriptionBespokePlanSelected,
        trackSubscriptionCreatedAndPendingCompletion,
        trackSubscriptionCancelConfirmationOpened,
        trackSubscriptionCancelled,
        trackSubscriptionResumeFromGracePeriodOpened,
        trackSubscriptionResumeFromGracePeriodCompleted,
        trackSubscriptionInvoicePendingPaymentClicked,
        trackSubscriptionInvoiceDownloaded,
        trackSubscriptionBusinessDetailsUpdated,
        trackSubscriptionDefaultPaymentMethodUpdated,
        trackSubscriptionPaymentMethodAdded,
        trackSubscriptionPaymentMethodRemoved,
        trackSubscriptionChange,
        trackSurveyCreated,
        trackSurveyDataExported,
        trackSurveyPublished,
        trackSurveyConsentReordered,
        trackSurveyConsentPreviewOpened,
        trackSurveyConsentUpdated,
        trackSurveyDeleted,
        trackSurveyScriptOptionsTemplateSelected,
        trackSurveyScriptAgeBracketsRangeGenerated,
        trackSurveyScriptPreviewOpened,
        trackSurveyScriptQuestionRemoved,
        trackSurveyScriptQuestionsReordered,
        trackSurveyScriptUpdated,
        trackSurveyUpdated,
        trackTagCreated,
        trackTagSelected,
        trackTeamCreated,
        trackTeamDeleted,
        trackTeamUpdated,
        trackTeamMemberActivated,
        trackTeamMemberCreated,
        trackTeamMemberDeactivated,
        trackTeamMemberDeleted,
        trackTeamMemberUpdated,
        trackTeamSettingsUpdated,
        trackUpgradeBannerClicked
    };
};

export default useMixpanel;
