import {Link} from '@inertiajs/react';
import {m} from 'framer-motion';
import Logo from '@/components/Logo';
import useData from '@/hooks/useData';

const SiteLogoHomeButton: React.FC = () => {
    const {currentUser} = useData(['currentUser']);

    return (
        <m.div
            className="mb-2 inline-flex flex-shrink-0 items-center px-4"
            initial={{translateX: -50}}
            whileHover={{translateX: -4}}
        >
            <img src="/images/face-nav.png" alt="" className="-my-1 mr-4 h-10 w-auto" />
            <Link href={currentUser.home_url}>
                <Logo size="sm" theme="secondary" />
            </Link>
        </m.div>
    );
};

export default SiteLogoHomeButton;
