import {PropsWithChildren} from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

interface Props {
    delayDuration?: number;
    label: string;
}

const Item: React.FC<PropsWithChildren<Props>> = ({children, delayDuration = 0, label}) => (
    <TooltipPrimitive.Root delayDuration={delayDuration}>
        <TooltipPrimitive.Trigger data-testid="tooltip" asChild>
            {children}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content className="z-50 mx-4 max-w-sm whitespace-pre-wrap rounded bg-black px-2 pb-1 pt-0.5 text-center text-sm text-white">
            {label}
            <TooltipPrimitive.Arrow />
        </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
);

const Tooltip = {
    Provider: TooltipPrimitive.Provider,
    Item
};

export default Tooltip;
