import {PropsWithChildren, useEffect, useMemo} from 'react';
import {Link, usePage} from '@inertiajs/react';
import {
    AddressBook,
    List,
    ListDashes,
    Pencil,
    TreeStructure,
    UsersFour,
    X
} from '@phosphor-icons/react';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import {m} from 'framer-motion';
import {useAtom} from 'jotai';
import OnboardingNewUserDialog from '@/components/Dialog/OnboardingNewUser';
import MainErrorFallback from '@/components/Error/Fallback';
import Logo from '@/components/Logo';
import Menu from '@/components/Nav/Menu';
import UserMenu from '@/components/Nav/UserMenu';
import SiteLogoHomeButton from '@/components/SiteLogoHomeButton';
import Toaster from '@/components/Toast';
import TrialBanner from '@/components/TrialBanner';
import useData from '@/hooks/useData';
import useI18n from '@/hooks/useI18n';
import {sidebarAtom} from '@/utils/store';

interface Props {
    hasSidebar?: boolean;
}

export const Wrapper: React.FC<PropsWithChildren<PropsWithChildren<Props>>> = ({children}) => (
    <div className="flex h-screen overflow-hidden bg-gray-100">{children}</div>
);

const navClass = 'mr-3 h-5 w-5 text-current transition duration-100 ease-in-out';

const Sidebar: React.FC = () => {
    const [sidebar, setSidebar] = useAtom(sidebarAtom);
    const {t} = useI18n();
    const {component, url} = usePage();
    const {currentUser, orgAdmin, team} = useData(['currentUser', 'orgAdmin', 'team']);

    const viewConsentByTeamUser =
        currentUser.permissions.consent_section.viewAny && team && currentUser.role !== orgAdmin;
    const showOrgMenu = currentUser.role === orgAdmin || viewConsentByTeamUser;
    const showTeamMenu = !!team;

    const orgNavigation = useMemo(
        () => [
            {
                href: 'teams.index',
                icon: <TreeStructure className={navClass} />,
                id: 'Teams/Index',
                isActive: component === 'Teams/Index',
                label: t('navigation.teams'),
                visible: currentUser.permissions.team.viewAny
            },
            {
                href: 'users.index',
                icon: <UsersFour className={navClass} />,
                id: 'Users/Index',
                isActive:
                    component === 'Users/Index' ||
                    (component === 'Users/Edit' && !url.includes('/teams/')),
                label: t('navigation.users'),
                visible: currentUser.permissions.user.viewAny && currentUser.role === orgAdmin
            },
            {
                href: 'respondents.index',
                icon: <AddressBook className={navClass} />,
                id: 'Respondents/Index',
                isActive:
                    component === 'Respondents/Index' ||
                    (component === 'Users/Edit' &&
                        !url.includes('/teams/') &&
                        !url.includes('edit')),
                label: t('navigation.respondents'),
                visible: currentUser.permissions.respondent.viewAny && currentUser.role === orgAdmin
            }
        ],
        [component]
    );
    const teamNavigation = useMemo(
        () => [
            {
                href: 'teams.projects.index',
                icon: <ListDashes className={navClass} />,
                id: 'Teams/Projects/Index',
                isActive:
                    component.startsWith('Teams/Projects/') || component.startsWith('Surveys/'),
                label: t('navigation.projects'),
                visible: currentUser.permissions.project.viewAny
            },
            {
                href: 'team.users.index',
                icon: <UsersFour className={navClass} />,
                id: 'Teams/Users/Index',
                isActive: component === 'Teams/Users/Index' || component === 'Users/Edit',
                label: t('navigation.teamMembers'),
                visible: currentUser.permissions.user.viewAny
            },
            {
                href: 'team.respondents.index',
                icon: <AddressBook className={navClass} />,
                id: 'Teams/Respondents/Index',
                isActive:
                    component.startsWith('Teams/Respondents/') ||
                    component === 'Respondents/Show' ||
                    component === 'Respondents/Edit',
                label: t('navigation.respondents'),
                visible: currentUser.permissions.respondent.viewAny
            },
            {
                href: 'teams.edit',
                icon: <Pencil className={navClass} />,
                id: 'Teams/Consent',
                isActive: component === 'Teams/Edit',
                label: t('navigation.editTeam'),
                visible: currentUser.permissions.team.update
            }
        ],
        [component]
    );
    const orgNavItems = orgNavigation.filter(nav => nav.visible);
    const teamNavItems = team?.id ? teamNavigation.filter(nav => nav.visible) : [];

    useEffect(() => {
        setSidebar({...sidebar, isOpen: false, isVisible: true});
    }, [component]);

    return (
        <>
            <div className="md:hidden">
                {sidebar.isOpen && (
                    <div className="fixed inset-0 z-60 flex">
                        <div className="fixed inset-0">
                            <div className="absolute inset-0 bg-gray-600 opacity-75" />
                        </div>
                        {sidebar.isOpen && (
                            <m.div
                                className="relative flex w-full max-w-xs flex-1 flex-col bg-teal-900"
                                initial={{x: -200}}
                                animate={{x: 0}}
                                exit={{x: -200}}
                                transition={{duration: 0.4}}
                            >
                                <div className="absolute right-0 top-0 -mr-14 p-1">
                                    <button
                                        className="flex h-12 w-12 items-center justify-center rounded-full focus:bg-gray-600 focus:outline-none"
                                        aria-label="Close sidebar"
                                        onClick={() => setSidebar({...sidebar, isOpen: false})}
                                    >
                                        <svg
                                            className="h-6 w-6 text-white"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <SiteLogoHomeButton />
                                <div className="h-0 flex-1 space-y-6 overflow-y-auto pb-4 pt-6">
                                    {showOrgMenu && orgNavItems.length > 0 && (
                                        <Menu items={orgNavItems} />
                                    )}
                                    {showTeamMenu && teamNavItems.length > 0 && (
                                        <Menu
                                            heading={currentUser?.team?.name || ''}
                                            items={teamNavItems}
                                        />
                                    )}
                                </div>
                                <UserMenu />
                            </m.div>
                        )}
                        <div className="w-14 flex-shrink-0"></div>
                    </div>
                )}
            </div>
            <div className="hidden md:flex md:flex-shrink-0">
                <m.div
                    className="flex flex-col overflow-hidden bg-teal-800"
                    animate={sidebar.isVisible ? 'open' : 'closed'}
                    variants={{
                        open: {width: 280, transition: {duration: 0.4}},
                        closed: {width: 0, transition: {duration: 0.4}}
                    }}
                    transition={{duration: 0.4, ease: 'spring'}}
                >
                    <div className="flex h-0 w-[280px] flex-1 flex-col overflow-y-auto py-6">
                        <SiteLogoHomeButton />

                        <div className="space-y-6 pt-6">
                            {showOrgMenu && orgNavItems.length > 0 && <Menu items={orgNavItems} />}
                            {showTeamMenu && teamNavItems.length > 0 && (
                                <Menu heading={team?.name || ''} items={teamNavItems} />
                            )}
                        </div>
                    </div>
                    <UserMenu />
                </m.div>
            </div>
        </>
    );
};

const Main: React.FC<PropsWithChildren<Props>> = ({children}) => (
    <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <main
            className="relative flex flex-1 animate-fadeIn flex-col overflow-y-auto focus:outline-none"
            tabIndex={0}
        >
            <Toaster />
            {children}
        </main>
    </div>
);

const MobileHeader: React.FC = () => {
    const {t} = useI18n();
    const {currentUser} = useData(['currentUser']);
    const [sidebar, setSidebar] = useAtom(sidebarAtom);

    if (!sidebar.isVisible) return null;

    return (
        <div className="flex items-center justify-between bg-teal-800 px-4 py-4 shadow-sm md:hidden">
            <Link
                href={currentUser.home_url}
                className="ease flex flex-shrink-0 items-center transition duration-100 hover:opacity-70"
            >
                <Logo size="sm" theme="secondary" />
            </Link>
            <button
                className={clsx(
                    'duration ease inline-flex items-center justify-center space-x-1 rounded-xl px-2 py-1 text-sm text-white/60 shadow-xl outline-none transition hover:text-white focus:ring-2 focus:ring-teal-200 focus:ring-offset-2',
                    {'opacity-0': sidebar.isOpen, 'opacity-100': !sidebar.isOpen}
                )}
                onClick={() => setSidebar({...sidebar, isOpen: !sidebar.isOpen})}
            >
                {sidebar.isOpen ? (
                    <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                    <List className="block h-6 w-6" aria-hidden="true" />
                )}
                <span>{t('navigation.menu')}</span>
            </button>
        </div>
    );
};

const Layout: React.FC<PropsWithChildren<Props>> = ({children, hasSidebar = true}) => (
    <Wrapper>
        {hasSidebar && <Sidebar />}
        <Main>
            <MobileHeader />
            <Sentry.ErrorBoundary
                fallback={({error}) => <MainErrorFallback error={error} type="main" />}
            >
                {children}
            </Sentry.ErrorBoundary>
            <TrialBanner />
        </Main>
        <OnboardingNewUserDialog />
    </Wrapper>
);

export const DefaultLayout = {
    Layout,
    Wrapper,
    Sidebar,
    Main
};

export default Layout;
