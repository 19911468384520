import React, {forwardRef} from 'react';
import {Link} from '@inertiajs/react';
import clsx from 'clsx';
import Spinner from '@/components/Spinner';

interface Props extends UIButton {
    children: React.ReactNode;
    forceNewTab?: boolean;
    isFetching?: boolean;
    size?: keyof typeof SIZE_CLASSES;
    style?: keyof typeof STYLE_CLASSES;
    theme?: keyof typeof THEME_CLASSES;
}

export const SIZE_CLASSES = {
    xs: 'px-2 py-1 sm:px-2.5 sm:py-1.5 text-xs',
    sm: 'px-3 py-2 sm:min-h-[30px] sm:px-3 sm:py-1.5 text-[15px] lg:px-3',
    md: 'px-3 py-1 min-h-[32px] text-sm sm:text-base sm:min-h-[30px] sm:px-4 sm:py-1.5'
};

export const BASE_CLASS =
    'duration-200 ease-out inline-block font-medium leading-[1.5] inline-flex items-center space-x-1.5 focus:outline-none active:opacity-90 focus:ring focus:ring-offset-2 whitespace-nowrap shadow-sm';

export const THEME_CLASSES = {
    primary:
        'bg-teal-600 border border-teal-600 text-white hover:bg-teal-500 hover:border-teal-500 focus:ring-teal-200',
    secondary:
        'bg-teal-100 border border-teal-100 text-teal-800 hover:bg-teal-200 hover:border-teal-200 focus:ring-teal-200',
    tertiary:
        'bg-yellow-100 border border-yellow-100 text-black hover:bg-yellow-200 hover:border-yellow-200 focus:ring-yellow-100 text-yellow-800',
    gray: 'bg-gray-100 border border-gray-100 text-teal-800 hover:border-gray-200 hover:bg-gray-150 focus:ring-gray-200',
    white: 'bg-white border border-gray-200 text-teal-800 hover:border-gray-300 hover:bg-gray-50 focus:ring-gray-200',
    destructive:
        'bg-red-100 border border-red-100 text-red-600 hover:border-red-500 hover:bg-red-500 hover:text-white focus:ring-red-200',
    transparent:
        'bg-transparent border border-transparent text-teal-800 hover:border-transparent hover:bg-transparent focus:ring-transparent',
    help: 'bg-yellow-100/50 border border-yellow-200 text-teal-600 hover:border-yellow-200  focus:ring-yellow-200'
};

export const STYLE_CLASSES = {
    default: 'rounded-xl',
    circle: 'rounded-full'
};

export const STATE_CLASSES = ({disabled, isFetching}: UIButton) => {
    return clsx({
        'pointer-events-none opacity-60': disabled,
        'cursor-wait': isFetching
    });
};

const Button: React.FC<Props> = forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>(
    (
        {
            children,
            className = '',
            disabled,
            forceNewTab,
            href,
            isFetching,
            size = 'sm',
            style = 'default',
            theme = 'primary',
            ...props
        },
        ref
    ) => {
        const classes = `${BASE_CLASS} ${className} ${THEME_CLASSES[theme]} ${
            STYLE_CLASSES[style]
        } ${SIZE_CLASSES[size]} ${STATE_CLASSES({disabled, isFetching})}`;
        const childrenByStatus = isFetching ? (
            <>
                <span className="mr-1 flex items-center space-x-2">{children}</span>
                <Spinner />
            </>
        ) : (
            children
        );

        if (href) {
            const isSameOrigin = href.startsWith(window.location.origin) || href.startsWith('/');

            if (!isSameOrigin || forceNewTab) {
                return (
                    <a href={href} className={classes} {...props} target="_blank" rel="noreferrer">
                        {childrenByStatus}
                    </a>
                );
            }

            return (
                <Link href={href} className={classes} {...props}>
                    {childrenByStatus}
                </Link>
            );
        } else {
            return (
                <button type="button" className={classes} ref={ref} {...props}>
                    {childrenByStatus}
                </button>
            );
        }
    }
);
Button.displayName = 'Button';

export default Button;
