import React, {PropsWithChildren, createContext, useEffect} from 'react';
import mixpanel, {type Config, type Mixpanel} from 'mixpanel-browser';

const config: Pick<Config, 'track_pageview'> = {
    track_pageview: true
};
const token = __MIXPANEL_TOKEN__;

export const MixpanelContext = createContext<Mixpanel | undefined>(undefined);

const MixpanelProvider: React.FC<PropsWithChildren> = ({children}) => {
    useEffect(() => {
        mixpanel.init(token, config);
    }, [token, config]);

    return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>;
};

export default MixpanelProvider;
