import {PropsWithChildren} from 'react';

interface Props {
    className?: string;
    size?: keyof typeof SIZES;
    theme?: keyof typeof THEMES;
}

export const THEMES = {
    white: 'bg-white text-teal-800 border-gray-150/50',
    blue: 'bg-blue-100 border-blue-200/50 text-blue-800',
    green: 'bg-green-100 border-green-200/50 text-green-800',
    gray: 'bg-gray-100 border-gray-200/50 text-gray-800',
    orange: 'bg-orange-100 border-orange-200/50 text-orange-800',
    red: 'bg-red-100 border-red-200/50 text-red-800',
    yellow: 'bg-yellow-100 border-yellow-200/50 text-yellow-800',
    'fatima-teal': 'bg-teal-100 border-teal-200/50 text-teal-800',
    'fatima-teal-dark': 'bg-teal-800 border-teal-800/50 text-white'
};

const SIZES = {
    xs: 'px-1.5 py-0.5 text-[10px] sm:px-2 sm:pt-[3px] sm:pb-[4px] tracking-tight',
    sm: 'px-1.5 py-0.5 text-[10px] sm:text-[11px] sm:py-1 tracking-tight',
    md: 'px-1.5 py-1 text-[11px] sm:px-[6px] sm:py-[5px] tracking-tight xl:text-[12px] lg:px-[8px]'
};

const Badge: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
    size = 'md',
    theme = 'fatima-teal'
}) => (
    <span
        className={`inline-flex flex-shrink-0 items-center rounded-full border font-medium leading-none tracking-wide ${
            className ? className : ''
        } ${THEMES[theme]} ${SIZES[size]}`}
    >
        {children}
    </span>
);

export default Badge;
