import {Link} from '@phosphor-icons/react';
import {toast} from 'sonner';
import Button from '@/components/Button';
import useI18n from '@/hooks/useI18n';

const CopyCurrentUrlButton: React.FC = () => {
    const {t} = useI18n();

    const handleCopyUrl = async () => {
        await navigator.clipboard.writeText(window.location.href);
        toast(t('general.copiedToClipboard'));
    };

    return (
        <Button onClick={handleCopyUrl} theme="white" type="button">
            <Link className="h-5 w-5" />
        </Button>
    );
};

export default CopyCurrentUrlButton;
