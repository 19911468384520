import {PropsWithChildren} from 'react';
import {Link} from '@inertiajs/react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import useData from '@/hooks/useData';
import useRoutes from '@/hooks/useRoutes';

interface Props {
    className?: string;
    href: string;
    id: string;
    isActive: boolean;
    isNested?: boolean;
}

export const getItemClass = (className: string, isActive: boolean, isNested?: boolean) => {
    return clsx(
        `flex w-full items-center rounded-xl px-2 text-sm font-medium leading-5 outline-none transition duration-500 ease-in-out data-[state="open"]:bg-gray-50 data-[state="open"]:text-teal-800 ${className}`,
        {
            'hover:duration-100 focus:bg-teal-600 focus:text-white focus:ring-2 focus:ring-teal-400':
                !isActive,
            'bg-teal-900 !text-white hover:bg-teal-900': isActive,
            'py-2 text-white/80 hover:bg-teal-600 hover:text-white': !isNested,
            'bg-white py-2 text-gray-600 hover:bg-teal-100 data-[state="closed"]:hover:bg-teal-100 data-[state="closed"]:hover:text-teal-600':
                isNested
        }
    );
};

const NavItem: React.FC<PropsWithChildren<Props>> = ({
    children,
    className = '',
    href,
    isActive,
    isNested
}) => {
    const {getRoute} = useRoutes();
    const {team} = useData(['team']);

    return (
        <NavigationMenu.Link asChild>
            <Link
                href={getRoute(href, {team: team?.id})}
                className={getItemClass(className, isActive, isNested)}
            >
                {children}
            </Link>
        </NavigationMenu.Link>
    );
};

export default NavItem;
