import {PropsWithChildren, useEffect} from 'react';
import {X} from '@phosphor-icons/react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import Button from '@/components/Button';
import ConditionalWrapper from '@/components/ConditionalWrapper';
import CopyCurrentUrlButton from '@/components/CopyCurrentUrlButton';
import useDialog from '@/hooks/useDialog';
import useI18n from '@/hooks/useI18n';

interface Props extends UIDialog {
    className?: string;
    contentClassName?: string;
    footer?: (close: () => void) => React.ReactNode;
    handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
    showCopyCurrentUrlButton?: boolean;
    size?: keyof typeof SIZE_CLASSES;
}

interface FormFooterProps {
    closeDialog: () => void;
    isDisabled?: boolean;
    isPending?: boolean;
    cancelLabel?: string;
    showCopyCurrentUrlButton?: boolean;
    submitLabel?: string;
}

export const SIZE_CLASSES = {
    md: 'sm:max-w-md',
    lg: 'sm:max-w-lg xl:max-w-xl',
    xl: 'sm:max-w-xl xl:max-w-2xl',
    '2xl': 'sm:max-w-xl md:max-w-2xl lg:max-w-3xl',
    '3xl': 'sm:max-w-xl md:max-w-3xl lg:max-w-4xl',
    '4xl': 'sm:max-w-xl md:max-w-3xl lg:max-w-5xl',
    '5xl': 'sm:max-w-xl md:max-w-3xl lg:max-w-6xl',
    full: 'max-w-[calc(100vw-2rem)]'
};

const Dialog: React.FC<PropsWithChildren<Props>> = ({
    cancel,
    children,
    className = '',
    confirm,
    contentClassName = '',
    footer,
    handleSubmit,
    isFetching,
    shouldClose,
    showCopyCurrentUrlButton,
    size = 'lg',
    text,
    title
}) => {
    const dialog = useDialog(confirm || null, cancel, isFetching);
    const {t} = useI18n();

    useEffect(() => {
        if (shouldClose && !dialog.isClosing) {
            dialog.prepareClose('cancel');
        }
    }, [shouldClose]);

    return (
        <DialogPrimitive.Portal>
            <DialogPrimitive.Overlay asChild>
                <div
                    className={`fixed bottom-0 left-0 right-0 top-0 z-[51] w-full bg-gray-700 bg-opacity-75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 ${className}`}
                    onClick={() => dialog.prepareClose('cancel')}
                />
            </DialogPrimitive.Overlay>
            <div className={`fixed inset-0 z-[51] overflow-hidden ${className}`}>
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <DialogPrimitive.Content
                            className={`pointer-events-auto w-screen overflow-hidden outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-right-1/2 data-[state=open]:slide-in-from-right-1/2 ${SIZE_CLASSES[size]}`}
                            data-testid="dialog-content"
                        >
                            <ConditionalWrapper
                                condition={!!handleSubmit}
                                wrapper={children => (
                                    <form
                                        className="flex h-full flex-col self-start"
                                        onSubmit={handleSubmit}
                                    >
                                        {children}
                                    </form>
                                )}
                            >
                                <div className="flex h-full w-full flex-col self-start bg-white shadow-xl drop-shadow">
                                    <div
                                        className={`scrollbar-rounded-md h-0 flex-1 overflow-y-auto scrollbar-thin scrollbar-track-teal-100 scrollbar-thumb-teal-600 ${contentClassName}`}
                                    >
                                        <div className="top-0 z-50 bg-teal-800 px-4 py-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                {title && (
                                                    <DialogPrimitive.Title
                                                        className="sticky top-0 text-2xl font-medium text-white"
                                                        dangerouslySetInnerHTML={{__html: title}}
                                                    />
                                                )}
                                                <div className="absolute right-2 top-2 flex h-7 items-center">
                                                    <DialogPrimitive.Close
                                                        asChild
                                                        onClick={() =>
                                                            dialog.prepareClose('cancel')
                                                        }
                                                    >
                                                        <button
                                                            type="button"
                                                            className="rounded-lg bg-teal-900 bg-opacity-80 p-1 text-white  outline-none ring-offset-2 transition duration-200 ease-out hover:bg-opacity-100 focus:bg-opacity-100 focus:ring-2 focus:ring-teal-400"
                                                        >
                                                            <X className="h-5 w-5" />
                                                        </button>
                                                    </DialogPrimitive.Close>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                {text && (
                                                    <DialogPrimitive.Description className="text-sm text-white text-opacity-80">
                                                        {text}
                                                    </DialogPrimitive.Description>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-1 flex-col justify-between">
                                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div className="space-y-6 py-6">{children}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer className="mb-[-1px] flex flex-shrink-0 items-center justify-between border-t border-gray-100 bg-gray-50 px-6 py-4">
                                        <div>
                                            {showCopyCurrentUrlButton && <CopyCurrentUrlButton />}
                                        </div>
                                        <div className="flex flex-grow items-center justify-end space-x-2">
                                            {footer ? (
                                                footer(() => dialog.prepareClose('cancel'))
                                            ) : (
                                                <Button
                                                    onClick={() => dialog.prepareClose('cancel')}
                                                    theme="white"
                                                    type="button"
                                                >
                                                    {cancel?.label || t('general.close')}
                                                </Button>
                                            )}
                                        </div>
                                    </footer>
                                </div>
                            </ConditionalWrapper>
                        </DialogPrimitive.Content>
                    </div>
                </div>
            </div>
        </DialogPrimitive.Portal>
    );
};

export const FormFooter: React.FC<FormFooterProps> = ({
    closeDialog,
    isDisabled,
    isPending,
    cancelLabel,
    submitLabel
}) => {
    const {t} = useI18n();

    return (
        <div className="flex w-full items-center justify-between space-x-6">
            <div className="flex flex-grow items-center justify-end space-x-2">
                <Button disabled={isPending} onClick={closeDialog} theme="white" type="button">
                    {cancelLabel || t('general.cancel')}
                </Button>
                <Button disabled={isDisabled || isPending} isFetching={isPending} type="submit">
                    {submitLabel || t('general.save')}
                </Button>
            </div>
        </div>
    );
};

const DialogWrapper = {
    Root: DialogPrimitive.Root,
    Trigger: DialogPrimitive.Trigger,
    Container: Dialog,
    FormFooter
};

export default DialogWrapper;
