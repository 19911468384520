import {useEffect} from 'react';
import useMixpanel from '@/hooks/useMixpanel';
import ErrorMessage from './Message';

interface Props {
    error: Error;
    type: 'app' | 'main';
}

const ErrorFallback: React.FC<Props> = ({error, type}) => {
    const {trackErrorFallback} = useMixpanel();

    useEffect(() => {
        trackErrorFallback(type, error.message);
    }, []);

    return <ErrorMessage message={error.message} />;
};

export default ErrorFallback;
