import {memo} from 'react';

interface Props {
    children: React.ReactNode;
    condition: any;
    // eslint-disable-next-line no-unused-vars
    fallbackWrapper?: (children: React.ReactNode) => React.ReactNode;
    // eslint-disable-next-line no-unused-vars
    wrapper?: (children: React.ReactNode) => React.ReactNode;
}

const ConditionalWrapper: React.FC<Props> = ({
    children,
    condition,
    fallbackWrapper,
    wrapper
}: Props) => {
    if (condition && wrapper) {
        return <>{wrapper(children)}</>;
    } else if (fallbackWrapper) {
        return <>{fallbackWrapper(children)}</>;
    } else {
        return <>{children}</>;
    }
};

export default memo(ConditionalWrapper);
