import {PropsWithChildren} from 'react';
import clsx from 'clsx';
import FormError from './Error';
import FormLabel from './Label';

const FormFieldWrapper: React.FC<PropsWithChildren<FormFieldWrapper>> = ({
    badge,
    children,
    description,
    error,
    fieldWrapperClasses = '',
    id,
    label,
    labelAside,
    labelClasses = '',
    showErrors,
    textDirection,
    wrapperClasses
}) => (
    <div className={wrapperClasses}>
        {label && (
            <FormLabel
                badge={badge}
                className={clsx(`flex items-center space-x-2 ${labelClasses}`, {
                    'direction-rtl': textDirection === 'rtl'
                })}
                label={label}
                id={id}
            >
                {labelAside}
            </FormLabel>
        )}
        <div className={`shadow-sm/50 relative rounded-md ${fieldWrapperClasses}`}>{children}</div>
        {description && <p className="mt-2 text-[13px] text-gray-500">{description}</p>}
        {showErrors && error && <FormError errors={[error]} />}
    </div>
);

export default FormFieldWrapper;
