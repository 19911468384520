import {useEffect} from 'react';
import {Toaster as ToasterPrimitive, toast} from 'sonner';
import useData from '@/hooks/useData';
import useI18n from '@/hooks/useI18n';

const Toaster: React.FC = () => {
    const {t} = useI18n();
    const {flash} = useData(['flash']);

    // ensure flash messages are translation keys and translated strings from the backend
    const getTextForFlashType = (message: string) => {
        // check if it has any spaces, this indicates text and not a key
        if (message.includes(' ')) {
            return message;
        }

        // @ts-ignore
        return t(message);
    };

    useEffect(() => {
        if (flash.error || flash.success) {
            // The flash must have been set within the last 1 second
            if (flash.time && Date.now() - flash.time > 1000) {
                return;
            }

            // Temp fix for flash message not showing up after purchase.
            // The toast is manually shown so we can't show the flash that _will_ appear
            // when moving from the subscription overview page.
            if (flash.success?.includes('subscription has been started') && flash.time) {
                return;
            }

            if (flash.success) {
                toast.success(getTextForFlashType(flash.success));
            } else if (flash.error) {
                toast.error(getTextForFlashType(flash.error));
            }
        }
    }, [flash]);

    return <ToasterPrimitive position="top-right" richColors />;
};

export default Toaster;
