import {atom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';

export const sidebarAtom = atom<{isOpen: boolean; isVisible: boolean}>({
    isOpen: false,
    isVisible: true
});
sidebarAtom.debugLabel = 'sidebar';

export const defaultSurveyEditorState: SurveyEditorAtom = {
    activeConsentSectionIndex: 0,
    activeTagIndex: 0,
    activeScriptQuestionIndex: 0,
    consentForm: null,
    errors: {},
    languages: [],
    lastUpdated: null,
    lastQuestionAdded: null,
    projectId: null,
    primaryLanguage: '',
    selectedLanguage: '',
    scriptForm: null,
    showPrimaryLanguageVersion: true,
    survey: null,
    tagsForm: null,
    textDirection: 'ltr'
};
export const surveyEditorAtom = atom<SurveyEditorAtom>(defaultSurveyEditorState);
surveyEditorAtom.debugLabel = 'surveyEditor';

export const scriptQuestionAtom = atom(
    get => {
        const {activeScriptQuestionIndex, scriptForm} = get(surveyEditorAtom);
        const question = scriptForm?.questions?.[activeScriptQuestionIndex];
        return question || null;
    },
    (get, set, value: any, key: string, locale: null | string) => {
        const {activeScriptQuestionIndex, scriptForm} = get(surveyEditorAtom);
        if (!scriptForm) return null;

        const newQuestion = {...scriptForm.questions[activeScriptQuestionIndex]};

        if (locale) {
            newQuestion[key][locale] = value;
        } else {
            newQuestion[key] = value;
        }

        set(surveyEditorAtom, {
            ...get(surveyEditorAtom),
            scriptForm: {
                ...scriptForm,
                questions: scriptForm.questions.map((question, index) => {
                    if (index === activeScriptQuestionIndex) return newQuestion;
                    return question;
                })
            }
        });
    }
);
scriptQuestionAtom.debugLabel = 'scriptQuestion';

// TODO: Combine these two derived survey atoms into one
export const consentSectionAtom = atom(
    get => {
        const {activeConsentSectionIndex, consentForm} = get(surveyEditorAtom);
        const section = consentForm?.sections?.[activeConsentSectionIndex];
        return section || null;
    },
    (get, set, value: any, key: string, locale: null | string) => {
        const {activeConsentSectionIndex, consentForm} = get(surveyEditorAtom);
        if (!consentForm) return null;

        const newSection = {...consentForm.sections[activeConsentSectionIndex]};

        if (locale) {
            newSection[key][locale] = value;
        } else {
            newSection[key] = value;
        }

        set(surveyEditorAtom, {
            ...get(surveyEditorAtom),
            consentForm: {
                ...consentForm,
                sections: consentForm.sections.map((question, index) => {
                    if (index === activeConsentSectionIndex) return newSection;
                    return question;
                })
            }
        });
    }
);
consentSectionAtom.debugLabel = 'consentSection';

export const scriptTagAtom = atom(
    get => {
        const {activeTagIndex, tagsForm} = get(surveyEditorAtom);
        const tag = tagsForm?.tags?.[activeTagIndex];
        return tag || null;
    },
    (get, set, value: any, tagIndex: number, locale: string) => {
        const {tagsForm} = get(surveyEditorAtom);
        if (!tagsForm) return null;

        set(surveyEditorAtom, {
            ...get(surveyEditorAtom),
            tagsForm: {
                ...tagsForm,
                tags: tagsForm.tags.map((tag, index) => {
                    if (index === tagIndex) {
                        return {...tag, name: {...tag.name, [locale]: value}};
                    }

                    return tag;
                })
            }
        });
    }
);
scriptTagAtom.debugLabel = 'scriptTag';

export const listViewAtom = atomWithStorage<'question' | 'respondent'>('listView', 'question');
listViewAtom.debugLabel = 'listView';

export const surveyDataDefault = {
    filters: {
        ages: [],
        genders: [],
        languages: [],
        questions: [],
        tags: [],
        types: []
    },
    hasFetchedResponses: false,
    query: '',
    indexedResponses: {},
    interviews: [],
    processing: false,
    responses: [],
    selectedLanguage: '',
    surveyId: null
};
export const surveyDataAtom = atom<SurveyDataAtom>(surveyDataDefault);
surveyDataAtom.debugLabel = 'surveyData';

export const listFiltersAtom = atom<ListFilter>({});
listFiltersAtom.debugLabel = 'listFilters';
