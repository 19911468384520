import {PropsWithChildren} from 'react';
import {m} from 'framer-motion';

interface Props {
    className?: string;
    delay?: number;
    duration?: number;
    y?: number;
}

const AnimatedFadeIn: React.FC<PropsWithChildren<Props>> = ({
    children,
    className = '',
    delay = 0,
    duration = 0.4,
    y = 8
}) => (
    <m.div
        className={className}
        variants={{idle: {opacity: 0, y}, enter: {opacity: 1, y: 0}}}
        initial="idle"
        animate="enter"
        transition={{delay, duration}}
    >
        {children}
    </m.div>
);

export default AnimatedFadeIn;
