import useData from './useData';
import enGB from '@/../lang/en-GB.json';
import esES from '@/../lang/es-ES.json';
import frFR from '@/../lang/fr-FR.json';

const allTranslations = {
    'en-GB': enGB,
    'es-ES': esES,
    'fr-FR': frFR
};

type LocaleKey = keyof (typeof allTranslations)['en-GB'];
const defaultLocale = 'en-GB';

const useI18n = () => {
    const {currentUser} = useData(['currentUser']);
    const locale = currentUser?.locale as keyof typeof allTranslations;
    const translations = allTranslations?.[locale] || allTranslations[defaultLocale];

    const getDynamicValue = (
        value: string,
        dynamicValues: Record<string, number | string> | null
    ) => {
        if (!value || !dynamicValues) return value;

        let translation: string = value;
        for (const [key, value] of Object.entries(dynamicValues)) {
            translation = translation.replace(`{{${key}}}`, value.toString());
        }
        return translation;
    };

    const t = (
        key: LocaleKey,
        dynamicValues: Record<string, number | string> | null = null,
        dynamicKeys: any[] = []
    ) => {
        const valueKey = dynamicKeys.length ? `${key}.${dynamicKeys.join('.')}` : key;
        const value =
            translations[valueKey as LocaleKey] || `[MISSING] ${valueKey} ${dynamicKeys.join('.')}`;

        return value !== undefined ? getDynamicValue(value, dynamicValues) : key;
    };

    return {t};
};

export default useI18n;
