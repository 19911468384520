import {ReactNode} from 'react';
import {createRoot} from 'react-dom/client';
import {IntercomProvider} from 'react-use-intercom';
import '../css/main.css';
import {createInertiaApp, router} from '@inertiajs/react';
import * as Sentry from '@sentry/react';
import Axios from 'axios';
import {LazyMotion} from 'framer-motion';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import NProgress from 'nprogress';
import 'plyr/dist/plyr.css';
import ErrorMessage from '@/components/Error/Message';
import Layout from '@/components/Layouts/Default';
import MixpanelProvider from '@/components/Mixpanel';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
import.meta.glob(['../fonts/**']);

Sentry.init({
    dsn: __CLIENT_SENTRY_DSN__,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
});

interface Props {
    children: ReactNode;
}

// https://github.com/spatie/form-backend-validation/issues/97#issuecomment-1462243656
if (typeof window !== 'undefined') {
    // @ts-ignore
    window.axios = Axios;
}

const loadFeatures = () => import('./motion').then(res => res.default);

const Wrapper: React.FC<Props> = ({children}) => (
    <>
        <LazyMotion features={loadFeatures}>{children}</LazyMotion>
    </>
);

router.on('start', () => NProgress.start());
router.on('finish', () => NProgress.done());

createInertiaApp({
    progress: {delay: 250, color: '#c7e7eb'},
    resolve: name => {
        const page = resolvePageComponent(
            `./Pages/${name}.tsx`,
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            import.meta.glob('./Pages/**/*.tsx')
        );
        // @ts-ignore
        page.layout = page.layout || Layout;
        return page;
    },
    setup({el, App, props}) {
        createRoot(el).render(
            <Sentry.ErrorBoundary fallback={({error}) => <ErrorMessage message={error.message} />}>
                <MixpanelProvider>
                    <IntercomProvider appId={__INTERCOM_APP_ID__}>
                        <Wrapper>
                            <App {...props} />
                        </Wrapper>
                    </IntercomProvider>
                </MixpanelProvider>
            </Sentry.ErrorBoundary>
        );
    },
    title: title => `${title} | Fatima`
}).catch(err => console.error(err));
