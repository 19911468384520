interface Props {
    errors: string[] | Record<string, string>;
}

const FormError: React.FC<Props> = ({errors}) => {
    if (Array.isArray(errors) && !errors?.filter(i => i).length) return null;
    if (typeof errors === 'object' && !Object.keys(errors).length) return null;

    const allErrors = Array.isArray(errors) ? errors : Object.values(errors).flat();

    return <p className="mt-2 animate-shake text-sm text-red-600">{allErrors.join(', ')}</p>;
};

export default FormError;
