import {useState} from 'react';

interface CTA {
    label?: string;
    onClick: () => void;
}

const useDialog = (
    confirm: CTA | null | undefined,
    cancel: CTA | null | undefined,
    isFetching?: boolean
) => {
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const prepareClose = (type: 'confirm' | 'cancel') => {
        if (isClosing || isFetching) return;

        if (type === 'cancel' && cancel) {
            setIsClosing(true);
            setTimeout(() => {
                if (cancel.onClick) {
                    cancel.onClick();
                }
                setIsClosing(false);
            }, 100);
        } else if (type === 'confirm' && confirm) {
            confirm.onClick();
        }
    };

    return {isClosing, setIsClosing, prepareClose};
};

export default useDialog;
