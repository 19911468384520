import route from 'ziggy-js';

const useRoutes = () => {
    const getUrlParams = (key: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    };

    const getRoute = route;

    return {getRoute, getUrlParams, route};
};

export default useRoutes;
