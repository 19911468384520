import Badge, {THEMES} from '@/components/Badge';
import useData from '@/hooks/useData';
import useI18n from '@/hooks/useI18n';
import useSubscription from '@/hooks/useSubscription';

const SubscriptionBadge: React.FC = () => {
    const {state, plan} = useData(['state', 'plan']);
    const {hasExpiredTrial} = useSubscription();
    const {t} = useI18n();

    const lang = {
        pending: t('accountManagement.status.pending'),
        onGracePeriod: plan?.name ?? t('accountManagement.status.subscribed'),
        active: plan?.name ?? t('accountManagement.status.subscribed'),
        past_due: plan?.name ?? t('accountManagement.status.subscribed'),
        none: hasExpiredTrial
            ? t('accountManagement.status.trialExpired')
            : t('accountManagement.status.trial')
    };
    const themes = {
        pending: 'fatima-teal',
        onGracePeriod: 'yellow',
        active: 'fatima-teal',
        past_due: 'red',
        none: hasExpiredTrial ? 'red' : 'yellow'
    };

    return <Badge theme={themes[state] as keyof typeof THEMES}>{lang[state]}</Badge>;
};

export default SubscriptionBadge;
