import {useState} from 'react';
import {CaretDown, ChatText, Gear, Hand, Question, SignOut, User} from '@phosphor-icons/react';
import DropdownMenu from '@/components/DropdownMenu';
import SubscriptionBadge from '@/components/Subscription/SubscriptionBadge';
import useChatbot from '@/hooks/useChatbot';
import useData from '@/hooks/useData';
import useI18n from '@/hooks/useI18n';
import useMixpanel from '@/hooks/useMixpanel';
import useRoutes from '@/hooks/useRoutes';

const UserMenu: React.FC = () => {
    const {trackExternalLinkClicked, trackLogout} = useMixpanel();
    const {openChatbot} = useChatbot();
    const {t} = useI18n();
    const {getRoute} = useRoutes();
    const {currentUser} = useData(['currentUser']);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleLoggedOut = () => {
        trackLogout();
        localStorage.clear();
    };

    return (
        <footer className="w-[280px] rounded-t-xl bg-teal-700 p-4">
            <div className="mb-1">
                <DropdownMenu.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
                    <DropdownMenu.Trigger>
                        <div className="flex items-center space-x-2 text-left font-medium leading-5 text-white underline-offset-4 hover:underline">
                            <span>{currentUser.tenant.name}</span>
                            <CaretDown className="h-4 w-4 flex-shrink-0 text-white" weight="bold" />
                        </div>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                        <p className="rounded-t-xl p-4 pb-2 text-sm font-medium text-teal-800">
                            {t('userMenu.greeting', {name: currentUser.name})}
                        </p>
                        {currentUser.is_admin && (
                            <DropdownMenu.Item>
                                <DropdownMenu.Action
                                    href={getRoute('users.edit', {user: currentUser.id})}
                                    Icon={User}
                                    label={t('userMenu.updateAccount')}
                                    onClick={() => setIsMenuOpen(false)}
                                />
                            </DropdownMenu.Item>
                        )}
                        {currentUser.is_admin && (
                            <DropdownMenu.Item>
                                <DropdownMenu.Action
                                    href={getRoute('account.subscriptions.show')}
                                    Icon={Gear}
                                    label={t('userMenu.manageSubscription')}
                                    onClick={() => setIsMenuOpen(false)}
                                />
                            </DropdownMenu.Item>
                        )}
                        <DropdownMenu.Item>
                            <DropdownMenu.Action
                                href="/logout"
                                Icon={SignOut}
                                label={t('general.logout')}
                                method="post"
                                onSuccess={handleLoggedOut}
                                theme="destructive"
                            />
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            </div>
            <SubscriptionBadge />
            <div className="mt-4 space-y-1.5">
                <a
                    className="flex items-center space-x-2 text-xs text-white underline-offset-2 hover:underline"
                    href="https://guides.hellofatima.com"
                    onClick={() => trackExternalLinkClicked('guides', 'user menu')}
                    rel="noreferrer"
                    target="_blank"
                >
                    <Question className="h-5 w-5 text-white" />
                    <span>{t('userMenu.wiki')}</span>
                </a>
                <a
                    className="flex items-center space-x-2 text-xs text-white underline-offset-2 hover:underline"
                    href="https://links.fatimaresearch.com/support-request"
                    onClick={event => {
                        openChatbot('user menu');
                        event.preventDefault();
                    }}
                    rel="noreferrer"
                    target="_blank"
                >
                    <Hand className="h-5 w-5 text-white" />
                    <span>{t('userMenu.support')}</span>
                </a>
                <a
                    className="flex items-center space-x-2 text-xs text-white underline-offset-2 hover:underline"
                    href="https://links.fatimaresearch.com/share-feedback"
                    onClick={() => trackExternalLinkClicked('feedback', 'user menu')}
                    rel="noreferrer"
                    target="_blank"
                >
                    <ChatText className="h-5 w-5 text-white" />
                    <span>{t('userMenu.feedback')}</span>
                </a>
            </div>
        </footer>
    );
};

export default UserMenu;
