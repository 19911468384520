import {useIntercom} from 'react-use-intercom';
import useMixpanel from './useMixpanel';
import useSession from './useSession';

const useChatbot = () => {
    const {currentUser} = useSession();
    const {boot, showNewMessage} = useIntercom();
    const {trackChatbotOpened} = useMixpanel();

    const open = (source: string, attributes: Record<string, string> = {}) => {
        trackChatbotOpened(source);
        boot({
            actionColor: '#aeedf3',
            backgroundColor: '#1f5a6b',
            company: {
                companyId: currentUser.tenant.id.toString(),
                name: currentUser.tenant.name,
                plan: currentUser.tenant.subscription_type
            },
            customAttributes: {source, ...attributes},
            hideDefaultLauncher: true,
            name: currentUser.name,
            userId: currentUser.id.toString()
        });
        showNewMessage();
    };

    return {openChatbot: open};
};

export default useChatbot;
