import {Link, usePage} from '@inertiajs/react';
import {Timer, WarningCircle} from '@phosphor-icons/react';
import clsx from 'clsx';
import useI18n from '@/hooks/useI18n';
import useMixpanel from '@/hooks/useMixpanel';
import useSubscription from '@/hooks/useSubscription';

const TrialBanner: React.FC = () => {
    const {trackUpgradeBannerClicked} = useMixpanel();
    const {url} = usePage();
    const {hasActiveTrial, hasExpiredTrial} = useSubscription();
    const {t} = useI18n();

    const handleClick = () => trackUpgradeBannerClicked(url);

    if (!hasActiveTrial) return null;

    return (
        <>
            <div className="pt-12" />
            <div
                className={clsx('fixed bottom-0 z-[49] w-full p-4 text-sm backdrop-blur-lg', {
                    'bg-yellow-200/60 text-yellow-800': !hasExpiredTrial,
                    'bg-red-200/60 text-red-800': hasExpiredTrial
                })}
            >
                <div className="gap-1 sm:flex sm:items-center">
                    <div className="flex items-center gap-1">
                        {hasExpiredTrial ? (
                            <>
                                <WarningCircle className="h-5 w-5" />
                                <p>{t('subscriptionTrial.bannerEnded')}</p>
                            </>
                        ) : (
                            <>
                                <Timer className="h-5 w-5" />
                                <p>{t('subscriptionTrial.bannerActive')}</p>
                            </>
                        )}
                    </div>{' '}
                    <Link
                        className="font-medium underline underline-offset-2 hover:decoration-2"
                        href="/account/subscription"
                        onClick={handleClick}
                    >
                        {t('subscriptionTrial.upgradeTrigger')}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default TrialBanner;
