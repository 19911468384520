import useI18n from './useI18n';

const useHelp = () => {
    const {t} = useI18n();

    const topics = {
        automatedTranscription: {
            text: t('help.automatedTranscription'),
            url: 'https://guides.hellofatima.com/en/articles/9010838-transcriptions-and-translations'
        },
        consent: {
            text: t('help.consent'),
            url: 'https://guides.hellofatima.com/en/articles/8885931-scripting-consent'
        },
        dataExport: {
            text: t('help.dataExport'),
            url: 'https://guides.hellofatima.com/en/articles/9010573-exporting-your-data'
        },
        deleteRespondent: {
            text: t('help.deleteRespondent'),
            url: 'https://guides.hellofatima.com/en/articles/9010370-managing-participants'
        },
        deleteTeam: {
            text: t('help.deleteTeam'),
            url: 'https://guides.hellofatima.com/en/articles/8885955-setting-up-your-team'
        },
        emptyInterviews: {
            text: [
                t('help.emptyInterviews1'),
                t('help.emptyInterviews2'),
                t('help.emptyInterviews3'),
                t('help.emptyInterviews4'),
                t('help.emptyInterviews5')
            ],
            url: 'https://guides.hellofatima.com/en/articles/9010592-scheduling-an-interview'
        },
        emptyRespondents: {
            text: t('help.emptyRespondents'),
            url: 'https://guides.hellofatima.com/en/articles/9010370-managing-participants'
        },
        emptyTranslations: {
            text: t('help.emptyTranslations'),
            url: 'https://guides.hellofatima.com/en/articles/9010563-translating-and-transcribing-survey-scripts'
        },
        overviewAgeComparison: {
            text: t('help.overviewAgeComparison'),
            url: 'https://guides.hellofatima.com/en/articles/9010489-creating-a-survey'
        },
        projectsAndSurveys: {
            text: t('help.projectsAndSurveys'),
            url: 'https://guides.hellofatima.com/en/articles/9010489-creating-a-survey'
        },
        remoteInterview: {
            text: t('help.remoteInterview'),
            url: 'https://guides.hellofatima.com/en/articles/9010599-conducting-a-remote-interview-on-the-fatima-research-hub'
        }
    };

    const getTopic = (topic: keyof typeof topics): {text: string | string[]; url?: string} =>
        topics[topic];

    return {
        getTopic,
        topics
    };
};

export type HelpTopics = keyof ReturnType<typeof useHelp>['topics'];

export default useHelp;
