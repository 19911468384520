import {PropsWithChildren} from 'react';
import EmptyPanel from './Panel';

interface Props {
    hasListFilters?: boolean;
    Icon?: React.ReactNode;
    text: string;
    title?: string;
}

const Empty: React.FC<PropsWithChildren<Props>> = ({
    children,
    hasListFilters,
    Icon = null,
    text,
    title
}) => (
    <EmptyPanel Icon={Icon} title={title}>
        <span className="text-sm text-gray-500">
            {text}
            {hasListFilters && ' Try changing the filters.'}
        </span>
        {children && <div>{children}</div>}
    </EmptyPanel>
);

export default Empty;
