import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import NavItem, {getItemClass} from './Item';

interface MenuItem {
    href: string;
    icon?: React.ReactNode;
    isActive: boolean;
    id: string;
    label: string;
    visible: boolean;
}

interface NestedMenuItem {
    icon: React.ReactNode;
    id: string;
    isActive: boolean;
    items: MenuItem[];
    label: string;
    visible: boolean;
}

interface Props {
    heading?: string;
    items: Array<MenuItem | NestedMenuItem>;
}

const isNestedMenuItem = (menuItem: MenuItem | NestedMenuItem): menuItem is NestedMenuItem => {
    return (menuItem as NestedMenuItem).items !== undefined;
};

const Menu: React.FC<Props> = ({heading, items}) => (
    <div className="relative">
        {heading && (
            <span className="tracking mb-2 block px-4 font-medium leading-snug text-white">
                {heading}
            </span>
        )}
        <NavigationMenu.Root className="px-2" delayDuration={0} orientation="vertical">
            <NavigationMenu.List className="space-y-1">
                {items
                    .filter(item => {
                        if (item?.visible === false) return false;
                        return true;
                    })
                    .map(item => (
                        <NavigationMenu.Item key={item.id} className="relative z-[50]">
                            {isNestedMenuItem(item) ? (
                                <>
                                    <NavigationMenu.Trigger className={getItemClass('', false)}>
                                        {item.icon}
                                        {item.label}
                                        <NavigationMenu.Content className="z-100 w-full data-[motion=from-end]:animate-enterFromRight data-[motion=from-start]:animate-enterFromLeft data-[motion=to-end]:animate-exitToRight data-[motion=to-start]:animate-exitToLeft">
                                            <div className="p-1">
                                                {item.items.map(nestedItem => {
                                                    return (
                                                        <NavItem
                                                            {...nestedItem}
                                                            key={nestedItem.id}
                                                            isNested={true}
                                                        >
                                                            {nestedItem.icon || (
                                                                <span className="w-7" />
                                                            )}
                                                            {nestedItem.label}
                                                        </NavItem>
                                                    );
                                                })}
                                            </div>
                                        </NavigationMenu.Content>
                                    </NavigationMenu.Trigger>
                                </>
                            ) : (
                                <NavItem {...item}>
                                    {item.icon}
                                    {item.label}
                                </NavItem>
                            )}
                        </NavigationMenu.Item>
                    ))}
            </NavigationMenu.List>
            <div className="perspective-[2000px] absolute left-4 top-full z-50 -mt-2 ml-2 flex w-[calc(100%-2rem)] justify-center">
                <NavigationMenu.Viewport className="relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] transition-[width,_height] duration-300 data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn" />
            </div>
        </NavigationMenu.Root>
    </div>
);

export default Menu;
