import {PropsWithChildren} from 'react';
import ConditionalWrapper from '../ConditionalWrapper';
import Badge from '@/components/Badge';

interface Props {
    badge?: string;
    className?: string;
    id?: string;
    label: string;
}

const FormLabel: React.FC<PropsWithChildren<Props>> = ({
    badge,
    children,
    className = '',
    label,
    id
}) => (
    <label
        htmlFor={id}
        className={`mb-1.5 flex select-all justify-between space-x-4 text-sm font-medium leading-5 text-teal-800 ${className}`}
    >
        <ConditionalWrapper
            condition={!!children}
            wrapper={wrapperChildren => (
                <>
                    <span>{wrapperChildren}</span>
                    {children}
                </>
            )}
        >
            <span>{label}</span>
            {badge && <Badge theme="blue">{badge}</Badge>}
        </ConditionalWrapper>
    </label>
);

export default FormLabel;
