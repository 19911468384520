import {usePage} from '@inertiajs/react';

const useData = <PagePropsKeys extends keyof PageProps>(
    keys: PagePropsKeys[]
): {[Key in PagePropsKeys]: PageProps[Key]} => {
    const pageProps = usePage().props as unknown as PageProps;
    const result = {} as {[Key in PagePropsKeys]: PageProps[Key]};

    keys.forEach(key => {
        result[key] = pageProps[key];
    });

    return result;
};

export default useData;
