import clsx from 'clsx';

interface Props {
    className?: string;
    hasError?: boolean;
    isActive?: boolean;
    isComplete?: boolean;
    isCurrent?: boolean;
    size?: 'sm' | 'md';
    theme?: 'teal' | 'yellow';
}

const SIZE_CLASSES = {
    sm: 'min-w-[26px] px-1.5 py-0.5 rounded-lg text-sm',
    md: 'min-w-[32px] px-2 py-1 rounded-xl text-sm'
};

const IndicatorBadge: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    className = '',
    hasError,
    isActive,
    isComplete,
    isCurrent,
    size = 'md',
    theme = 'yellow'
}) => (
    <span
        className={clsx(
            `inline-flex flex-shrink-0 items-center justify-center space-x-1 font-medium transition duration-200 ease-in-out ${className} ${SIZE_CLASSES[size]}`,
            {
                'bg-teal-800 text-white': (isActive || isComplete) && !isCurrent && !hasError,
                'bg-teal-200 text-teal-900': isCurrent && !isComplete && !hasError,
                'bg-teal-100 text-teal-800':
                    !(isActive || isComplete || isCurrent) && theme === 'teal' && !hasError,
                'bg-yellow-100 text-teal-800':
                    !(isActive || isComplete || isCurrent) && theme === 'yellow' && !hasError,
                'bg-red-600 text-white': hasError
            }
        )}
    >
        {children}
    </span>
);

export default IndicatorBadge;
